/**
 * 滚动网页设置网页头部样式
 */
function fixedHeader() {
  var scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
  var bannerHeight = document.getElementById('J_banner').clientHeight;
  if (scrollTop >= bannerHeight) {
    document.getElementById('J_header').className = 'header fixed';
  } else {
    document.getElementById('J_header').className = 'header';
  }
}

window.addEventListener('scroll', fixedHeader);
fixedHeader();
